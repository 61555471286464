<template>
    <div style="position: relative">
        <!-- <Sidebar /> -->
        <HeaderPage />
        <div class="privacy_policy-class">
            <div class="mt-7 pt-7 ps-3 me-2">
                <p class="ps-3 text_title">プライバシーポリシー</p>
                <div class="mt-4">
                    <span class="text_paragraph">
                        株式会社トーカイ薬局は、個人情報保護の重要性に鑑み、「個人情報の保護に関する法律」及び本プライバシーポリシーを遵守し、お客さまのプライバシー保護に努めます。
                    </span>
                </div>
                <div class="mt-4">
                    <p class="text_size">個人情報の定義</p>
                </div>
                <div class="mt-4 ">
                    <p class="text_paragraph">お客さま個人に関する情報(以下「個人情報」といいます)であって、お客さまのお名前、住所、電話番号など当該お客さま個人を識別することができる情報をさします。他の情報と組み合わせて照合することにより個人を識別することができる情報も含まれます。</p>
                </div>
                <div class="mt-4 ">
                    <p class="text_paragraph">プライバシーポリシー　 （個人情報保護宣言） （インターネットを利用したショッピングモール業務）</p>
                </div>
                <div class="mt-4 ">
                    <p class="text_paragraph">当社は、お客様から安心して情報を提供いただき有効に利用させていただくため、個人情報保護に関する基本方針としてこのプライバシーポリシーを制定し、すべての役職員がこれを遵守し適正な取扱と安全管理の徹底を図っていくことを宣言します。</p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        1.個人情報の取得・利用について
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        ①当社は、お客様の個人情報を適切かつ適法な手段により取得し、公表する利用目的［インターネットを利用したショッピングモール（以下ECモール）業務］に基づき、利用させていただきます。利用目的の達成に必要な範囲を超えた利用はいたしません。<br>
                        ②利用目的の具体的内容は、インターネットホームページへの掲載にて公表のうえ、契約書その他の書面にてお客様から直接個人情報を取得する際はその利用目的を明示いたします。<br>
                        ③当社では、例えば、以下のような情報源から、お客様の個人情報を取得いたします。<br>
                        ・ECモールの会員登録ページ等お客さまに登録いただくフォームにて提供される場合<br>
                        ・ECモールの出店申込書等の書面にて提供される場合<br>
                        ④当社はお客さまにとって利用目的が明確になるよう具体的に定めるとともに、例えば「各種アンケート等の集計のためのみに利用します」等取得の場面に応じ、利用目的を限定するよう努めます。<br>
                        ⑤当社はダイレクトメールの送付や電話等での勧誘のダイレクトマーケティングで個人情報を利用することについて、お客様さまより中止のお申し出があった場合は、当該目的での個人情報の利用を中止いたします。<br>
                        ⑥当社では例えば以下のような場合に個人データの取扱いの委託を行っています。<br>
                        ・情報システムの運用、保守に関わる業務<br>
                        ・キャンペーン商品等の発送に関わる業務
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        2.個人データの第三者への提供について
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        当社は、法令等により認められる場合又はご利用規約に定める場合を除き、お客様の同意をいただくことなくお客様の個人データを第三者に提供することはありません。<br>
                        ただし、下記に定める事項の場合は、第三者に該当しないものとして、お客様の同意をいただくことなくお客様の個人データを提供することがあります。
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        3.個人データの委託
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        当社は、利用目的の達成に必要な範囲内において、お客様の個人データの取扱を外部に委託することがあります。
                        個人データの共同利用
                        ①当社は、利用目的の達成に必要な範囲内において、お客様の個人データを共同利用することがあります。
                        ②具体的な内容は、インターネットホームページへ掲載しております。
                        3.センシティブ情報の取扱について 当社は、個人情報の保護に関する法律(以下「法」)第2条第3項に定める要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保険医療および性生活(これらのうち要配慮個人情報に該当するものを除く)に関する情報
                        ＜本人、国の機関、地方公共団体、法第76条第1項各号もしくは個人情報の保護に関する法律施行規則第6条各号に掲げる者により公開されているもの、または本人を目視し、もしくは撮影することにより取得するその外形上明らかなものを除く。以下いわゆる「機微(センシティブ)情報」＞
                        については、法令等に基づく場合や適切な業務運営を確保する必要性からお客様の同意をいただいた場合等を除き、原則として取得・利用・第三者提供はいたしません。
                        ＜要配慮個人情報＞ 本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱に特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいう。
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        4.個人データの安全管理について
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        ①当社は情報資産管理責任者のもとで、個人データの漏洩、改ざん、紛失、破壊等に対する安全管理のため、個人データへのアクセス管理、持出の制限、外部からの不正アクセス防止などの措置を講じたうえで、定期的な点検、監査を実施し、継続的に態勢の見直しを行ってまいります。<br>
                        ②当社は役職員ひとりひとりの意識を継続的に徹底させ個人情報の保護に対する自覚と責任をもつことが重要な課題であると認識しており、必要な教育計画を定め、すべての役職員に対してこれを実施し啓蒙を図ってまいります。<br>
                        ③当社は、お客様からご提供いただいた個人情報の外部委託を行う場合には、委託先の適切な選定を行い、委託先に対して必要かつ適切な監督を行ってまいります。
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        5.遵守すべき法令、規範について
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        当社は、個人情報の保護に関する法律などの諸法令およびこれを適切に運用するために告示された各省庁のガイドラインや業界自主ルール等を遵守し、これらを取りまとめた社内規程に基づき全役職員一丸となって個人情報の保護に取組んでまいります。
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        6.個人情報保護に対する継続的改善
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        当社は、個人情報の保護を適切に行うため、社会情勢の変化、法令等の改正、点検、監査の実施結果に基づき、このプライバシーポリシー、社内規程、運用管理体制等の見直しを継続的に行い、改善を図ってまいります。
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        7.保有個人データの開示等の手続について
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        ①当社は、お客様からご自身に関する保有個人データの情報開示等の依頼があった場合は、ご本人様であることを確認させていただいたうえで、法令の定めに従いお答えいたします。具体的な内容については、「お客様の個人情報の取扱について」と題してインターネットホームページにおいて公表いたしますので、内容をご確認のうえ当社までご連絡ください。なお、ご請求には一定の手数料が必要になりますので、ご了承ください。<br>
                        ②お客様本人から、ご自身に関する保有個人データの内容が事実に反する等の理由による当該保有個人データの訂正、追加、削除または利用停止、消去、第三者提供の停止のご要望があった場合、必要な調査を行ったうえで誠実かつ迅速な対応を行うよう努めてまいります。
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_size">
                        8.個人情報の取扱に関する苦情等
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text_paragraph">
                        当社は、個人情報の取扱に関する苦情等の問合せに適切かつ迅速に対応するため、「苦情等のお問い合わせ窓口」を設置し、誠意ある対応により苦情処理に対処いたします。
                    </p>
                </div>
            </div>
            <!-- <v-fab-transition>
                <v-btn
                v-scroll="onScroll"
                v-show="fab"
                class="mx-2"
                fab
                dark
                small
                fixed
                bottom
                right
                style="height: 50px; width: 50px"
                color="#ff0090"
                @click="toTop"
                >
                <v-icon dark size="40">
                    mdi-chevron-up
                </v-icon>
                </v-btn>
            </v-fab-transition> -->
        </div>
        <div class="mt-7" style="margin-bottom: 8rem">
            <v-card class="d-flex justify-center" flat>
                <v-btn @click.prevent="$router.push('/')" color="#ff0090" depressed class="white--text">
                {{ $t("message.go-shopping") }}
                </v-btn>
            </v-card>
            <CategoryFooter class="mb-0 p-0" />
        </div>
        <Sidebar />
    </div>
</template>

<script>
import Sidebar from '../../components/developmentv2/Sidebar.vue'
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import CategoryFooter from "../../components/CategoryFooter.vue";
export default {
    name: "PrivacyPolicy",
    data() {
        return {
            // fab: false
        }
    },
    components: {
        Sidebar,
        CategoryFooter,
        HeaderPage
    },
    beforeMount(){
        window.scrollTo(0,0)
    },
    methods: {
        // onScroll (e) {
        //     if (typeof window === 'undefined') return
        //     const top = window.pageYOffset ||   e.target.scrollTop || 0
        //     // const bottom = window.pageYOffset ||   e.target.scrollTop || 0
        //     // (window.innerHeight + window.scrollY) >= document.body.offsetHeight
        //     // console.log(bottom);
        //     this.fab = top > 20
        // },
        //     toTop () {
        //     this.$vuetify.goTo(0)
        //     // window.scrollTo({
        //     //   top: document.body.scrollHeight,
        //     //   behavior: "smooth"
        //     // })
        // }
    }
}
</script>

<style>
.privacy_policy-class {
    height: auto;
    padding: 0;
    margin: 0;
}
.text_title {
  border-left: 10px solid #ff0090;
  font-size: 18px;
}
.text_size {
    font-size: 14px;
}
.text_paragraph {
    white-space: pre-line;
    font-size: 12px;
}
</style>